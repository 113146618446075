@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');

#print-area {
  border-color: #aaa;
  border-style: dashed;
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 1em;
}

.details {
  display: flex;
  align-items: center;
}

.qrcode {
  margin-bottom: 1em;
}

.hidden {
  display: none;
}

textarea {
  background-color: #fff;
  border: solid 1px #ddd;
  font-family: 'Source Code Pro', serif;
  font-size: 1.3em;
  font-weight: bold;
  height: 3em;
  overflow: hidden;
  resize: none;
}

button {
  height: 50px;
  width: 180px;
}

button#print {
  color: #fff;
  background-color: #0074d9;
  border-color: #0074d9;
}

button#rotate {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

@media print {
  body * {
    visibility: hidden;
  }
  #print-area,
  #print-area * {
    visibility: visible;
  }
  #print-area textarea {
    border: none;
  }
  .no-print {
    display: none;
  }
  #print-area {
    position: absolute;
    left: 0;
    top: 0;
  }
}

hr {
  margin-top: 25px;
}
